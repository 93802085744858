/** @jsx jsx */
import { jsx } from 'theme-ui'
//

const Footer = () => (
  <footer
    sx={{
      display: 'block',
      pl: 1,
      pb: 2,
      pt: 4,
      fontSize: '.625rem',
      color: 'lightText',
      flexShrink: 0,
      height: '50px'
    }}
  >
    © 2021 Cristina Susano / Implemented by{' '}
    <a
      sx={{
        color: 'lightText',
        textDecoration: 'none'
      }}
      href="https://www.pauloamgomes.net"
      target="_blank"
      rel="noreferrer"
    >
      pauloamgomes
    </a>
  </footer>
)

export default Footer
