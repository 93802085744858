/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import { graphql } from 'gatsby'
//
import Layout from '../components/layout'
import Seo from '../components/seo'
import Footer from '../components/footer'
import MdxField from '../utils/mdx'

const PageTemplate = ({ data, location }) => {
  const { title, description, body } = data?.contentfulPage

  return (
    <Layout location={location}>
      <Seo title={title} description={description?.description} cardType="summary" />

      <Styled.h2 sx={{ mb: 1, fontWeight: 400 }}>{title}</Styled.h2>

      <Styled.div sx={{ width: '100%', maxWidth: '960px' }}>
        <MdxField>{body?.childMdx?.body}</MdxField>
      </Styled.div>

      <Footer />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(id: { eq: $id }) {
      id
      title
      description {
        description
      }
      body {
        childMdx {
          body
        }
      }
    }
  }
`
